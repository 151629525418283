import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateHome } from '../templates';
import { Lead, Paragraph, Element } from '@arturpol/style-guide/src/components';
export const meta = {
  title: 'Home',
  type: 'page',
  hint: 'Go to home page',
  order: 16,
  isPartiallyActive: false,
  seoTitle: 'UX/UI Design Agency for SaaS from Poland'
};
export const banner = {
  title: 'Human-Centric Applications',
  subtitle: 'that turn users into customers',
  cta: {
    label: 'Go to our portfolio',
    url: '/portfolio/'
  },
  bestAt: {
    title: 'Design for Engagement',
    content: <Lead mdxType="Lead">We design UI/UX that transform your data-heavy services into attractive and intuitive cross-platform SaaS applications.</Lead>
  },
  image: {
    alt: 'Human Design for Web & Mobile Applications'
  }
};
export const offer = {
  items: [{
    img: {
      src: '/images/product-design.jpg',
      alt: 'Product design',
      density: [1, 2, 4]
    },
    title: 'Product design',
    content: <Paragraph mdxType="Paragraph">We convert your ideas into a productive web &amp; mobile application. We optimize user flows then design wireframes and prototypes that achieve a perfect synergy of business, tech, and user needs.</Paragraph>,
    tags: ['web', 'mobile', 'app', 'UX', 'SaaS', 'startup']
  }, {
    img: {
      src: '/images/branding.jpg',
      alt: 'Brand identity',
      density: [1, 2, 4]
    },
    title: 'Brand identity',
    content: <Paragraph mdxType="Paragraph">We create a stunning visual system that makes your brand instantly recognizable. We blend style, colors and typography to ensure your brand speaks the same language as your audience.</Paragraph>,
    tags: ['branding', 'logo', 'graphics', 'style', 'look']
  }],
  cta: {
    label: 'Explore packages',
    url: '/offer/'
  }
};
export const why = {
  title: 'Why You Should Work with Us',
  sections: [{
    title: 'Is human design for me?',
    items: [{
      title: 'Get visuals in days',
      content: 'Not sure how your idea resonates with the audience? Test it quickly! Kickstart your project with our <em>Human Application Blueprint</em> and get a clickable application prototype in less than three weeks.'
    }, {
      title: 'Hook angels and investors faster',
      content: '60% of startups don’t land seed funding within three months and most <a href="https://techcrunch.com/2015/06/08/lessons-from-a-study-of-perfect-pitch-decks-vcs-spend-an-average-of-3-minutes-44-seconds-on-them/" title="Techcrunch study - Surviving the Fundraising Process" target="_blank" rel="nofollow">give up within two months</a>. To land investors faster, demonstrate working prototypes while others fumble and explain.'
    }, {
      title: 'Build for profit, design for experience',
      content: 'Get everything you need, quickly. Create a great user experience, a profitable model and launch without breaking the bank. Where others work in slow stages, we generate immediate synergy.'
    }]
  }, {
    title: 'How about the bottom line?',
    items: [{
      title: 'Don’t pay for the wrong idea',
      content: 'What features do you need? Is the market large enough? Let’s avoid uncertainties like these together. We’ll work with you to fully verify your assumptions before development begins.'
    }, {
      title: 'Avoid the redesign trap',
      content: 'A poor first concept traps you in an endless do-over cycle, draining your budget and adding months to the timeline. Skip the headaches and work with us to get it right the first time with a durable Version One.'
    }, {
      title: 'Avoid costly surprises',
      content: 'Software houses often blindly ‘blast out’ estimates from $5,000-$500,000 to see what will hook the client. You don’t want to get hooked, do you? Our <em>Human Application Blueprint</em> delivers an accurate valuation.'
    }]
  }]
};
export const portfolio = {
  title: 'Systems with a human touch',
  content: <Lead mdxType="Lead">Have a peek at the latest examples of human design</Lead>
};
export const testimonials = {
  title: <>What do our <Element as="span" className="break" mdxType="Element"><Element as="span" className="crossed" mdxType="Element">clients</Element> <Element as="em" mdxType="Element">partners</Element> say?</Element></>,
  cta: {
    label: 'Learn more about us',
    url: '/about/'
  },
  quotes: ['30k', 'showhow-2', 'atze', 'ranksense', 'avenon', 'moming']
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  banner,
  offer,
  why,
  portfolio,
  testimonials,
  _frontmatter
};
const MDXLayout = TemplateHome;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      